import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import HomeHeader from '../../components/common/HomeHeader';
import Header from '../../components/common/Header';
import ConsultationHeader from '../../components/common/ConsultationHeader';
import RewardsHeader from '../../components/common/RewardsHeader';
import Footer from '../../components/common/Footer';
import HomeContainer from '../home/HomeContainer';
import ConsultationTabContainer from '../home/ConsultationTabContainer';
import AccountContainer from '../home/AccountContainer';
import PolicyPurchased from '../policy/PolicyPurchased';
import RewardsTabContainer from '../home/RewardsTabContainer';
import { initializeAmplitudeClient } from '../../services/analytics';
import { initializeChatClient } from '../../services/chatClient';
import InsureTab from '../policy/InsureTab';
import CitiesList from '../consultation/CitiesList';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #fbfafc;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

class HomeTabContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};

    this.chatClient = initializeChatClient();
  }

  componentDidMount = () => {
    this.initializePubnubForVideo();
    const { authToken, loginState, history } = this.props;
    const { pathname, search } = this.props.location;
    const params = new URLSearchParams(search);
    if (
      (pathname === '/home/policy-purchased' &&
        !params.get('auth') &&
        !authToken &&
        loginState !== 'LOGGED_IN') ||
      (pathname !== '/home/policy-purchased' &&
        (loginState !== 'LOGGED_IN' || !authToken))
    ) {
      history.push('/');
    }
  };

  initializePubnubForVideo = () => {
    this.channelGroup = `visit-usr-group-${this.props.userInfo.userId}`;
    this.chatClient.subscribeForVideo(this.channelGroup);
  };

  componentDidUpdate = (prevProps, prevState) => {
    let prevSessionId = null;
    if (prevProps.videoCallPayload && prevProps.videoCallPayload.sessionId) {
      prevSessionId = prevProps.videoCallPayload.sessionId;
    }
    if (
      this.props.video.startCall &&
      this.props.video.videoCallPayload &&
      this.props.video.videoCallPayload.sessionId !== prevSessionId
    ) {
      this.props.history.push('/video');
    }
  };

  componentWillUnmount = () => {
    if (this.chatClient) {
      this.chatClient.unsubscribeForVideo(this.channelGroup);
    }
  };

  render() {
    console.log('hello', this.props);
    const { walletBalance, showFitCoins, sponsorLogo } = this.props;
    return (
      <OuterContainer>
        <Switch>
          <Route
            path="/home"
            exact
            render={(props) => (
              <HomeHeader
                balance={walletBalance ? walletBalance.toString() : '0'}
                showFitCoins={showFitCoins}
                sponsorLogo={sponsorLogo}
                {...props}
              />
            )}
          />
          <Route path="/home/consult" component={ConsultationHeader} />
          <Route path="/home/account" />
          <Route path="/home/sample" component={CitiesList} />
          <Route
            path="/home/rewards"
            render={(props) => (
              <RewardsHeader
                balance={walletBalance ? walletBalance.toString() : '0'}
                {...props}
              />
            )}
          />
        </Switch>
        <BodyContainer>
          <Switch>
            <Route path="/home" exact component={HomeContainer} />
            <Route path="/home/policy-purchased" component={PolicyPurchased} />
            <Route path="/home/consult" component={HomeContainer} />
            <Route path="/home/account" component={AccountContainer} />
            <Route path="/home/rewards" component={RewardsTabContainer} />
            <Route path="/home/insure" component={InsureTab} />
          </Switch>
        </BodyContainer>
        <Footer />
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  walletBalance: state.user.walletBalance,
  userInfo: state.user,
  authToken: state.user.authToken,
  loginState: state.app.loginState,
  showFitCoins: state.app.config.showFitCoins,
  sponsorLogo: state.app.config.sponsorLogo,
  video: state.video,
});

export default connect(mapStateToProps)(HomeTabContainer);
