import React from 'react';
import styled from 'styled-components';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { fontSize } from '../../utils';
import StyledTappable from '../common/StyledTappable';

import { Text } from '../common/Text';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const OptionContainer = styled(StyledTappable)`
  justify-content: flex-start;
  font-size: ${fontSize(1)};
  min-height: 2rem;
  padding: 12px 0px;
  border-bottom: 1px solid #f0f3f6;
`;

const OptionSelectedIcon = styled(MdRadioButtonChecked)`
  color: #8852cc;
`;

const OptionUnselectedIcon = styled(MdRadioButtonUnchecked)`
  color: #666;
`;

const OptionText = styled(Text)`
  padding-left: 12px;
  font-size: 16px;
  color: #3a2ca0;
`;

const SingleSelect = (props) => {
  const { value, setResponse, options } = props;
  const renderListOptions = (ele, index) => {
    return (
      <OptionContainer key={index} onTap={() => setResponse(ele)}>
        {value === ele ? (
          <div
            className="mr-12px bg-txtsecondary-shades4 rounded-full"
            style={{ width: '22px', height: '22px', padding: '5px' }}
          >
            <div
              className="mr-12px rounded-full text-center"
              style={{ width: '12px', height: '12px', background: '#e07a5f' }}
            ></div>
          </div>
        ) : (
          <div
            className="mr-12px bg-txtsecondary-shades4 rounded-full"
            style={{ width: '22px', height: '22px' }}
          ></div>
        )}
        <p className="text-title-m font-semibold text-icon">{ele}</p>
      </OptionContainer>
    );
  };

  return (
    <OuterContainer className="font-mont">
      {options.map(renderListOptions)}
    </OuterContainer>
  );
};

export default SingleSelect;
