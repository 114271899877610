import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaChevronRight } from 'react-icons/fa';

import { BoldText, RegularText } from '../../components/common/Text';
import StyledTappable from '../../components/common/StyledTappable';
import { logout } from '../../actions';

import { setPlaceholderImageForNodeUsingGender } from '../../utils/placeholder';
import { initializeAmplitudeClient } from '../../services/analytics';

import logoutIcon from '../../images/common/logout.svg';
import dependantIcon from '../../images/common/dependants.svg';
import faqIcon from '../../images/common/faq.svg';
import { logEvent } from '../../utils/logEvent';

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  overflow-y: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  padding: 24px;
  padding-top: 0;
  background: white;
  overflow-y: scroll;
  -webkit-overflow-scrolling: scroll;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  background: #e07a5f;
  padding: 24px;
`;

const ProfileImage = styled.img`
  width: 4rem;
  margin-right: 1.125rem;
  border-radius: 8px;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameText = styled(BoldText)`
  font-size: ${() => `${1.125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-bottom: 0.25rem;
  color: #ffffff;
`;

const UserDetailsText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #ffffff;
`;

const OptionContainer = styled(StyledTappable)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0;
  margin: 0.5rem 0;
`;

const OptionText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

class AccountContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showLogoutButton: false,
    };
  }

  componentDidMount = () => {
    logEvent('Accounts Tab', {});
    window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
      .then(() => {})
      .catch(() => {
        // either phonepe SDK does not exist, or something went wrong fetching grant token
        this.setState({
          showLogoutButton: true,
        });
      });

    if (this.props.user && this.props.user.userGender) {
      Array.from(document.getElementsByClassName('profile-image')).forEach(
        (ele) => {
          setPlaceholderImageForNodeUsingGender(
            ele,
            this.props.user.userGender.toLowerCase()
          );
        }
      );
    } else {
      Array.from(document.getElementsByClassName('profile-image')).forEach(
        (ele) => {
          setPlaceholderImageForNodeUsingGender(ele, null);
        }
      );
    }
  };

  render() {
    const { showLogoutButton } = this.state;
    const {
      userName,
      userGender,
      userAge,
      userPhone,
      countryCode,
      profileImage,
    } = this.props.user;
    const { history, dispatch, config } = this.props;
    console.log(profileImage);
    return (
      <OuterContainer>
        <UserContainer>
          <ProfileImage className="profile-image" src={profileImage} />
          <div className="flex flex-col text-textbox">
            <p className="font-bold text-body-l">{userName}</p>
            <p className="font-semibold text-body-s mt-4px">
              {userAge},{userGender}
            </p>
            <p className="font-semibold text-body-s mt-4px">{`${countryCode}-${userPhone}`}</p>
          </div>
        </UserContainer>
        <BodyContainer>
          {/* <OptionContainer
            onTap={() => {
              history.push('/refer');
            }}
          >
            <OptionText>Refer and Earn</OptionText>
            <FaChevronRight />
          </OptionContainer> */}
          {config && config.showManageDependent && (
            <div
              className="flex flex-row justify-between items-center mb-36px"
              onClick={() => {
                // initializeAmplitudeClient().logoutUser();
                // dispatch(logout(history));
                history.push('/manage-dependents');
              }}
            >
              <div className="flex flex-row items-center">
                <img src={dependantIcon} />
                <div className="ml-16px text-txtlight text-body-l font-medium">
                  Manage Dependents
                </div>
              </div>
              <FaChevronRight />
            </div>
          )}
          {config && config.faqsLink && (
            <div
              className="flex flex-row justify-between items-center mb-36px"
              onClick={() => {
                window.open(config.faqsLink, '_blank');
              }}
            >
              <div className="flex flex-row items-center">
                <img src={faqIcon} />
                <div className="ml-16px text-txtlight text-body-l font-medium">
                  FAQ
                </div>
              </div>
              <FaChevronRight />
            </div>
          )}
          {config && config.showAdditionalFaq && config.additionalFaqConfig && (
            <div
              className="flex flex-row justify-between items-center mb-36px"
              onClick={() => {
                window.location.assign(config.additionalFaqConfig.url);
              }}
            >
              <div className="flex flex-row items-center">
                <img src={faqIcon} />
                <div className="ml-16px text-txtlight text-body-l font-medium">
                  {config.additionalFaqConfig.title}
                </div>
              </div>
              <FaChevronRight />
            </div>
          )}
          {showLogoutButton && (
            <div
              className="flex flex-row justify-between items-center"
              onClick={() => {
                initializeAmplitudeClient().logoutUser();
                dispatch(logout(history));
                logEvent('Onboarding User Logged Out');
              }}
            >
              <div className="flex flex-row items-center">
                <img src={logoutIcon} />
                <div className="ml-16px text-txtlight text-body-l font-medium">
                  Sign Out
                </div>
              </div>
              <FaChevronRight />
            </div>
          )}
        </BodyContainer>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  config: state.app.config,
});

export default connect(mapStateToProps)(AccountContainer);
