import { Component } from 'react';
import styled from 'styled-components';

const ModalBoundary = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
`;

const Title = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dashed black;
  border-radius: 8px;
  font-weight: 500;
`;

const InnerBoundary = styled.div`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button1 = styled.button`
  width: 90%;
  padding: 14px;
  background-color: #e07a5f;
  color: #ffffff;
  border-radius: 8px;
`;

const Button2 = styled.button`
  width: 90%;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #e07a5f;
  color: #413958;
  border-radius: 8px;
`;

const Tag1 = styled.p`
  height: 16px;
  left: 8.75%;
  right: 8.75%;
  top: calc(50% - 16px / 2 + 2px);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #263069;
`;

const Tag2 = styled.p`
  height: 16px;
  left: 14.67%;
  right: 14.93%;
  top: calc(50% - 16px / 2 + 110px);
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #263069;
`;

const OrContainer = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
`;

const Line = styled.div`
  width: 70%;
  border-bottom: 1px solid black;
`;

const Or = styled.div`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  position: absolute;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background: #ffffff;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #263069;
`;

export default class HospitalizationModal extends Component {
  constructor(props) {
    super(props);
  }

  onClaimReimbursementHandler = () => {
    console.log('onClaimReimbursement');
    this.props.history.push('/hospitalization/claim-reimbursement');
  };

  onCashlessInitmationHandler = () => {
    console.log('onCashlessInitmationHandler');
    this.props.history.push('/hospitalization/cashless-intimation');
  };

  render() {
    const { title, method1, method2, tag1, tag2 } = this.props;
    return (
      <ModalBoundary>
        <Title>{title}</Title>
        <InnerBoundary>
          <Button1 onClick={this.onClaimReimbursementHandler}>
            {method1}
          </Button1>
          <Tag1>{tag1}</Tag1>
        </InnerBoundary>
        <OrContainer>
          <Line></Line>
          <Or>Or</Or>
        </OrContainer>
        <InnerBoundary>
          <Button2 onClick={this.onCashlessInitmationHandler}>
            {method2}
          </Button2>
          <Tag2>{tag2}</Tag2>
        </InnerBoundary>
      </ModalBoundary>
    );
  }
}
