import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import MaternityCareProgram from './MaternityCareProgram';
import MaternityUploadPrescription from './MaternityCareDocVerification';
import MaternityUploadPrescriptionDemo from './MaternityCareDocVerificationDemo';
import MaternityBenefitsPage from './MaternityBenefitsPage';
import MaternityBenefitsPageDemo from './MaternityBenefitPageDemo';

const MaternityCareProgramNavigator = ({ loginState, authToken, history }) => {
  if (loginState !== 'LOGGED_IN' || !authToken) {
    history.push('/');
    return false;
  }
  return (
    <Switch>
      <Route path="/maternity-care" exact component={MaternityCareProgram} />
      <Route
        path="/maternity-care/doc-verification"
        exact
        component={MaternityUploadPrescription}
      />
      <Route
        path="/maternity-care/direct-doc-verification"
        exact
        component={MaternityUploadPrescriptionDemo}
      />
      <Route
        path="/maternity-care/benefits"
        exact
        component={MaternityBenefitsPage}
      />
      <Route
        path="/maternity-care/benefits-new"
        exact
        component={MaternityBenefitsPageDemo}
      />
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  loginState: state.app.loginState,
});

export default connect(mapStateToProps)(MaternityCareProgramNavigator);
