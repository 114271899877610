import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import { FaArrowLeft } from 'react-icons/fa';

import { Text } from './Text';
import arrowLeft from '../../images/common/arrow-left.svg';
import HeadphonesIcon from '../../images/consultations/headphones-icon.svg';
import { useSelector } from 'react-redux';

const HeaderContainer = styled.div`
  z-index: 10;
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid #f0f3f6;
  padding: 16px;
  padding-top: 40px;
  width: 100%;
`;

const BackContainer = styled(Tappable)`
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const HeaderText = styled(Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #413958;
`;

const Header = ({
  title,
  history,
  onTap,
  hideBackButton,
  color,
  textColor,
  img,
  needHelp,
}) => {
  const { config } = useSelector((state) => state.app);
  const { authToken } = useSelector((state) => state.user);
  const openSupport = () => {
    window.open(
      `${config.ticketingUrl}?token=${authToken.split(' ')[1]}`,
      '_blank'
    );
  };
  return (
    <HeaderContainer color={color}>
      {!hideBackButton && (
        <BackContainer
          onTap={() => {
            if (onTap) {
              onTap();
            } else {
              history.goBack();
            }
          }}
        >
          <img src={arrowLeft} height={'32px'} width={'32px'} alt={'back'} />
        </BackContainer>
      )}
      <HeaderText color={textColor}>{title}</HeaderText>
      {needHelp && (
        <button
          className="flex justify-center text-secondary-shades6 cursor-pointer mr-8px ml-auto"
          onClick={openSupport}
        >
          <img src={HeadphonesIcon}></img>
          <p className="ml-12px font-regular text-body-s">Need help ?</p>
        </button>
      )}
    </HeaderContainer>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  hideBackButton: PropTypes.bool,
  onTap: PropTypes.func,
  color: PropTypes.string,
  textColor: PropTypes.string,
};

Header.defaultProps = {
  hideBackButton: false,
  color: 'white',
  textColor: 'black',
};

export default withRouter(Header);
