/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import styled from 'styled-components';

import Header from '../../components/common/Header';
import ImageCard from '../../components/home/ImageCard';
import tickIcon from '../../images/common/check.svg';
import pdfIcon from '../../images/lab-test/pdf-icon.svg';
import { connect } from 'react-redux';
import Tappable from 'react-tappable/lib/Tappable';
import {
  fetchClaimStatusSummaryV2,
  fetchClaimSummary,
  fetchClaimSummaryV2,
  uploadDocuments,
  getFeedbackDetails,
  knowMoreDetails,
} from '../../services';
import StyledTappable from '../../components/common/StyledTappable';
import { FlyInTransition } from '../../components/transitions/transitions';
import Modal from '../common/Modal';
import photoIcon from '../../images/lab-test/photo-icon.svg';
import fileIcon from '../../images/lab-test/file-icon.svg';
import MessageModal from '../../components/pharmacy/MessageModal';
import { logEvent } from '../../utils/logEvent';
import LoadingComponent from '../../components/common/LoadingComponent';
import HeadphonesIcon from '../../images/Claims-Page/headphones.svg';
import DetailsIcon from '../../images/reimbursement/detail_image.svg';
import DocumentuploadIcon from '../../images/reimbursement/Paper_Upload.svg';
import CheckTick from '../../images/reimbursement/Check_sq-fr.svg';
import NotificationIcon from '../../images/reimbursement/notification.svg';
import { FaTimes } from 'react-icons/fa';
import AlertIcon from '../../images/reimbursement/Alert.svg';
import RuppesIcon from '../../images/reimbursement/ruppe_icon.svg';
import CircleCheckIcon from '../../images/reimbursement/circle_check.svg';
import DeniedPermisionIcon from '../../images/reimbursement/denien_permission.svg';
import ClaimsKnowMore from '../../components/claims/ClaimsKnowMore';
import moment from 'moment';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 18px 14px;
  background: #fff;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1rem;
`;

const StepContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-left: 1px solid;
  border-left-style: dashed;
  padding: 16px 0;
`;

const AttachedFilesContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  flex-wrap: wrap;
`;

const AttachFileContainer = styled(Tappable)`
  position: relative;
  flex-shrink: 0;
  margin: 0 0.5rem;
  background-image: ${() => `url(${pdfIcon})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2rem;
  width: 45px;
  height: 64px;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
`;

const AttachImageContainer = styled(Tappable)`
  position: relative;
  flex-shrink: 0;
  margin: 0 0.5rem;
  background-image: ${(props) => `url(${props.fileUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  width: 45px;
  height: 64px;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px black;
`;

const StatusIcon = styled.div`
  border-radius: 50%;
  margin-left: -12px;
  height: 24px;
  width: 24px;
  align-self: flex-start;
  border: 3px solid;
  border-color: ${(props) => (props.active ? '#e07a5f' : '#EBEAF5')}
  background: #fff;
  padding: 8px;
`;

const StatusIconImg = styled.img`
  background: #e07a5f;
  margin-left: -12px;
  border-radius: 50%;
  align-self: flex-start;
  height: 24px;
  width: 24px;
`;

const StatusIncomDoc = styled.img`
  background: #ff754c;
  margin-left: -12px;
  border-radius: 50%;
  align-self: flex-start;
  height: 24px;
  width: 24px;
`;

const StepTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #e07a5f1a;
  border-radius: 8px;
  padding: 12px 16px;
  margin: 12px;
  margin-top: -12px;
  width: 100%;
`;

const StepstextContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 15px;
  padding: 0 12px;
  width: 100%;
`;

const StatusContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  background: #ffffff;
  border: 1px solid ${(props) => props.borderColor || '#e07a5f'};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
`;
const DateText = styled.p`
  margin-right 3px;
  margin-bottom: 24px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  color: #767787;
  position:absolute;
  right:2%;
  bottom: 0px;
`;

const StatusUploadSubContainer = styled.div`
  padding: 12px;
  margin-top: 15px;
  background: #f0f3f6;
  opacity: 0.8;
  border-radius: 12px;
`;

const StatusDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 9px;
  border-bottom-color: ${(props) => (props.bottomBorder ? '#f0f3f6' : '#fff')};
  border-bottom-width: ${(props) => (props.bottomBorder ? '1px' : '0px')};
`;

const StatusSubText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #0f0b28;
`;
const StatusSubLableText = styled.p`
  margin-top: 5px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #302e6b;
`;

const Text3 = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 18px;
  flex: 1;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.active ? '#413958' : '#767787')};
`;

const ModalAttachContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 1rem 0.75rem;
  padding-bottom: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const AttachOptionsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 1.25rem;
  margin-top: 1rem;
`;

const CloseContainer = styled(StyledTappable)`
  position: absolute;
  top: -5px;
  right: -5px;
  background: #ff7268;
  border-radius: 6px;
  padding: 2px;
`;

const CloseIcon = styled(FaTimes)`
  font-size: 14px;
  color: white;
`;

const AttachOption = styled(StyledTappable)`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  margin: 0 0.25rem;
`;

const AttachmentInput = styled.input`
  display: none;
`;

const AttachOptionInnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  background-color: white;
  overflow: hidden;
`;

const AttachOptionText = styled.p`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: center;
`;

const AttachOptionIcon = styled.img`
  width: 3.875rem;
  margin-bottom: 0.5rem;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

class ClaimsSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      loading: true,
      claimInfo: null,
      documents: [],
      documentsDeficient: [],
      documentsDeficientDetails: null,
      claimInfoV2: {},
      status: [],
      labReport: [],
      perscriptionFile: [],
      invoiceFile: [],
      borderColor: '#FF865C',
      invoiceFiles: [],
      labReports: [],
      showUploadOption: false,
      prescriptionFiles: [],
      invoiceFileParts: [],
      prescriptionFileParts: [],
      medsInvoiceFileParts: [],
      labsInvoiceFileParts: [],
      labsReportFileParts: [],
      merParts: [],
      showMessageModal: false,
      modalHeader: '',
      modalMessage: '',
      goBack: true,
      claimStatusDetails: [],
      paymentDetails: {},
      showKnowMoreModal: false,
      documentUploadExpired: false,
      knowMoreDetails: [],
    };
    if (props.location.state.claimId) {
      this.state.claimId = props.location.state.claimId;
    } else {
      this.props.history.goBack();
    }

    if (props.location.state.goBack !== null) {
      this.state.goBack = props.location.state.goBack;
    }
  }

  componentDidMount() {
    if (this.state.claimId) {
      this.getClaimStatusV2();
      // this.getClaimStatus();
      this.ClaimStatusSummaryV2();
    }
  }

  // getClaimStatus = () => {
  //   fetchClaimSummary(this.state.claimId, this.props.authToken)
  //     .then((res) => {
  //       const invoiceFile = res.claimInfo.documents.filter((ele) => {
  //         return ele.type === 'invoice';
  //       });
  //       const prescriptionFile = res.claimInfo.documents.filter((ele) => {
  //         return ele.type === 'prescription';
  //       });
  //       const merFile = res.claimInfo.documents.filter((ele) => {
  //         return ele.type === 'mer';
  //       });
  //       console.log(res);
  //       this.setState({
  //         loading: false,
  //         claimInfo: res.claimInfo,
  //         // status: res.claimInfo.status,
  //         invoiceFile,
  //         prescriptionFile,
  //         merFile,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  getClaimStatusV2 = () => {
    fetchClaimSummaryV2(this.state.claimId, this.props.authToken)
      .then((res) => {
        console.log('d', res);
        const invoiceFile = res.claimInfo.documents.filter((ele) => {
          return ele.type === 'invoice';
        });
        const prescriptionFile = res.claimInfo.documents.filter((ele) => {
          return ele.type === 'prescription';
        });
        const merFile = res.claimInfo.documents.filter((ele) => {
          return ele.type === 'mer';
        });
        const documentsDeficientDetails =
          res.claimInfo.status && res.claimInfo.status.length
            ? res.claimInfo.status.find(
                (s) =>
                  s.state === 'documents-deficient' &&
                  s.type === 'documents-incomplete'
              )
            : null;
        const documentUploadExpired =
          res.claimInfo.status && res.claimInfo.status.length
            ? res.claimInfo.status.find((ele) => {
                if (
                  ele.type === 'upload-document' &&
                  ele.active &&
                  ele.activeDateFormat
                ) {
                  if (
                    moment().diff(
                      moment(ele.activeDateFormat, 'DD-MM-YYYY'),
                      'days'
                    ) > 0
                  ) {
                    console.log('e', ele);
                    return ele;
                  }
                  return false;
                }
                return false;
              })
            : false;

        this.setState({
          loading: false,
          claimInfoV2: res.claimInfo,
          status: res.claimInfo.status,
          documentUploadExpired: documentUploadExpired ? true : false,
          documentsDeficient: res.claimInfo
            ? res.claimInfo.documentsDeficient
            : [],
          documentsDeficientDetails: documentsDeficientDetails || null,
          requestStatus: documentsDeficientDetails
            ? documentsDeficientDetails.state
            : null,
          invoiceFile,
          prescriptionFile,
          merFile,
        });
        console.log(
          'f',
          this.state.documentUploadExpired,
          documentsDeficientDetails
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  ClaimStatusSummaryV2 = () => {
    fetchClaimStatusSummaryV2(this.state.claimId, this.props.authToken)
      .then((res) => {
        console.log('status', res);
        this.setState({
          claimStatusDetails: res.claimInfo,
          paymentDetails: res.claimInfo.paymentDetails,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onAttachPhoto() {
    document.querySelector('#photoAttachment').click();
  }

  onAttachFile() {
    document.querySelector('#fileAttachment').click();
  }
  onImageDataReceive = () => {
    this.setState({ showModal: false });
    const inputElem = document.getElementById('photoAttachment');

    const file = inputElem.files[0];
    console.log({ file });
    if (this.state.imageType === 'invoice') {
      this.setState({
        invoiceFileParts: [
          ...this.state.invoiceFileParts,
          {
            name: 'invoiceFile',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    } else if (this.state.imageType === 'prescription') {
      console.log('s', this.state.imageType);
      this.setState({
        prescriptionFileParts: [
          ...this.state.prescriptionFileParts,
          {
            name: 'prescriptionFile',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    } else if (this.state.imageType === 'meds-invoice') {
      this.setState({
        medsInvoiceFileParts: [
          ...this.state.medsInvoiceFileParts,
          {
            name: 'medicineInvoice',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    } else if (this.state.imageType === 'labs-invoice') {
      this.setState({
        labsInvoiceFileParts: [
          ...this.state.labsInvoiceFileParts,
          {
            name: 'labInvoice',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    } else if (this.state.imageType === 'lab-report') {
      this.setState({
        labsReportFileParts: [
          ...this.state.labsReportFileParts,
          {
            name: 'labReport',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    } else if (this.state.imageType === 'mer') {
      this.setState({
        merParts: [
          ...this.state.merParts,
          {
            name: 'merFile',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    }
  };

  onPDFDataReceive = () => {
    this.setState({ showModal: false });
    const inputElem = document.getElementById('fileAttachment');

    const file = inputElem.files[0];
    if (this.state.imageType === 'invoice') {
      this.setState({
        invoiceFileParts: [
          ...this.state.invoiceFileParts,
          {
            name: 'invoiceFile',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    } else if (this.state.imageType === 'prescription') {
      this.setState({
        prescriptionFileParts: [
          ...this.state.prescriptionFileParts,
          {
            name: 'prescriptionFile',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    } else if (this.state.imageType === 'meds-invoice') {
      this.setState({
        medsInvoiceFileParts: [
          ...this.state.medsInvoiceFileParts,
          {
            name: 'medicineInvoice',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    } else if (this.state.imageType === 'labs-invoice') {
      this.setState({
        labsInvoiceFileParts: [
          ...this.state.labsInvoiceFileParts,
          {
            name: 'labInvoice',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    } else if (this.state.imageType === 'lab-report') {
      this.setState({
        labsReportFileParts: [
          ...this.state.labsReportFileParts,
          {
            name: 'labReport',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    } else if (this.state.imageType === 'mer') {
      this.setState({
        merParts: [
          ...this.state.merParts,
          {
            name: 'merFile',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    }
  };
  uploadDocs = (s) => {
    this.setState({ uploadingImage: true });
    const { baseUrl, authToken } = this.props;
    // console.log({ state: this.state, s }, '[updateReimbursement]');
    console.log('h', s);
    const {
      invoiceFileParts,
      prescriptionFileParts,
      prescriptionFiles,
      invoiceFiles,
      claimInfoV2,
      documentsDeficient,
      labsReportFileParts,
    } = this.state;
    logEvent('OPD Reimbursement Status Document Uploaded', {
      referenceId: claimInfoV2.reimbursementId,
      patientName: claimInfoV2.patientInfo
        ? claimInfoV2.patientInfo.name
        : null,
      procedureName: claimInfoV2.procedureName,
    });

    const formData = new FormData();
    formData.append('date', claimInfoV2.admissionDate);
    formData.append('reimbursementId', claimInfoV2.reimbursementId);
    // formData.append(
    //   'requestStatus',
    //   s.type === 'documents-incomplete' && s.showDetails ? 1 : 0
    // );
    // if (
    //   ((prescriptionFiles.length > 0 || prescriptionFileParts.length > 0) &&
    //     (invoiceFileParts.length > 0 || invoiceFiles.length) &&
    //     !(s.type === 'documents-incomplete' && s.showDetails)) ||
    //   (((documentsDeficient.includes('prescription') &&
    //     prescriptionFileParts.length > 0) ||
    //     !documentsDeficient.includes('prescription')) &&
    //     ((documentsDeficient.includes('invoice') &&
    //       invoiceFileParts.length > 0) ||
    //       !documentsDeficient.includes('invoice')) &&
    //     ((documentsDeficient.includes('lab-report') &&
    //       labsReportFileParts.length > 0) ||
    //       !documentsDeficient.includes('lab-report')) &&
    //     s.type === 'documents-incomplete' &&
    //     s.showDetails)
    // ) {
    if (invoiceFileParts.length > 0) {
      // body.push(invoiceFileParts);
      invoiceFileParts.forEach((file) => {
        formData.append('invoiceFile', file.data);
      });
    }
    // if (labsReportFileParts.length > 0) {
    //   // body.push(labsReportFileParts);
    //   labsReportFileParts.forEach((file) => {
    //     formData.append('labReport', file.data);
    //   });
    // }
    if (prescriptionFileParts.length > 0) {
      // body.push(prescriptionFileParts);
      prescriptionFileParts.forEach((file) => {
        formData.append('prescriptionFile', file.data);
      });
    }
    uploadDocuments(formData, authToken)
      .then((apiResponse) => {
        console.log({ apiResponse, baseUrl }, 'uploadFileUrl response');
        if (apiResponse.message === 'success') {
          this.setState({
            uploadingImage: false,
            showMessageModal: true,
            modalHeader: 'Additional Documents Submitted',
            modalMessage:
              'Please refer to the documents section below to see all uploaded documents',
          });
        } else if (apiResponse.message === 'failure') {
          this.setState({
            modalHeader: 'Error',
            showMessageModal: true,
            uploadingImage: false,
            modalMessage: apiResponse.errorMessage,
          });
        } else {
          this.setState({
            modalHeader: 'Image Not Supported',
            showMessageModal: true,
            uploadingImage: false,
            modalMessage: 'File format not supported! Upload a jpg/jpeg image.',
          });
        }
      })
      .catch((err) => {
        console.log({ err }, 'error response while calling uploadFileUrl');
        this.setState({ uploadingImage: false });
      });
    // } else {
    //   this.setState({ uploadingImage: false });
    //   alert('Oops', 'Please upload all required documents.');
    // }
  };

  updateReimbursement = (s) => {
    console.log({ state: this.state, s }, '[updateReimbursement]');
    this.setState({ uploadingImage: true });
    const { baseUrl, authToken } = this.props;
    // console.log({ state: this.state, s }, '[updateReimbursement]');
    console.log('h', s);
    const {
      invoiceFileParts,
      prescriptionFileParts,
      prescriptionFiles,
      invoiceFiles,
      claimInfoV2,
      documentsDeficient,
      labsReportFileParts,
    } = this.state;
    logEvent('OPD Reimbursement Status Document Uploaded', {
      referenceId: claimInfoV2.reimbursementId,
      patientName: claimInfoV2.patientInfo
        ? claimInfoV2.patientInfo.name
        : null,
      procedureName: claimInfoV2.procedureName,
    });

    const formData = new FormData();
    formData.append('date', claimInfoV2.admissionDate);
    formData.append('reimbursementId', claimInfoV2.reimbursementId);
    formData.append(
      'requestStatus',
      s.type === 'documents-incomplete' && s.showDetails ? 1 : 0
    );
    if (
      ((prescriptionFiles.length > 0 || prescriptionFileParts.length > 0) &&
        (invoiceFileParts.length > 0 || invoiceFiles.length) &&
        !(s.type === 'documents-incomplete' && s.showDetails)) ||
      (((documentsDeficient.includes('prescription') &&
        prescriptionFileParts.length > 0) ||
        !documentsDeficient.includes('prescription')) &&
        ((documentsDeficient.includes('invoice') &&
          invoiceFileParts.length > 0) ||
          !documentsDeficient.includes('invoice')) &&
        ((documentsDeficient.includes('lab-report') &&
          labsReportFileParts.length > 0) ||
          !documentsDeficient.includes('lab-report')) &&
        s.type === 'documents-incomplete' &&
        s.showDetails)
    ) {
      if (invoiceFileParts.length > 0) {
        // body.push(invoiceFileParts);
        invoiceFileParts.forEach((file) => {
          formData.append('invoiceFile', file.data);
        });
      }
      // if (labsReportFileParts.length > 0) {
      //   // body.push(labsReportFileParts);
      //   labsReportFileParts.forEach((file) => {
      //     formData.append('labReport', file.data);
      //   });
      // }
      if (prescriptionFileParts.length > 0) {
        // body.push(prescriptionFileParts);
        prescriptionFileParts.forEach((file) => {
          formData.append('prescriptionFile', file.data);
        });
      }
      uploadDocuments(formData, authToken)
        .then((apiResponse) => {
          console.log({ apiResponse, baseUrl }, 'uploadFileUrl response');
          if (apiResponse.message === 'success') {
            this.setState({
              uploadingImage: false,
              showMessageModal: true,
              modalHeader: 'Additional Documents Submitted',
              modalMessage:
                'Please refer to the documents section below to see all uploaded documents',
            });
          } else if (apiResponse.message === 'failure') {
            this.setState({
              modalHeader: 'Error',
              showMessageModal: true,
              uploadingImage: false,
              modalMessage: apiResponse.errorMessage,
            });
          } else {
            this.setState({
              modalHeader: 'Image Not Supported',
              showMessageModal: true,
              uploadingImage: false,
              modalMessage:
                'File format not supported! Upload a jpg/jpeg image.',
            });
          }
        })
        .catch((err) => {
          console.log({ err }, 'error response while calling uploadFileUrl');
          this.setState({ uploadingImage: false });
        });
    } else {
      this.setState({ uploadingImage: false });
      alert('Oops', 'Please upload all required documents.');
    }
  };
  hideModal = () => {
    this.getClaimStatusV2();
    // this.getClaimStatus();
    this.setState({
      showMessageModal: false,
      showKnowMoreModal: false,
    });
  };

  onProceed = () => {
    knowMoreDetails(
      this.state.claimInfoV2.reimbursementId,
      this.props.authToken
    )
      .then((res) => {
        console.log(res);
        this.setState({
          knowMoreDetails: res.data,
          showKnowMoreModal: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toFeedbackPage = () => {
    // console.log(claimId);
    getFeedbackDetails(
      this.state.claimInfoV2.reimbursementId,
      this.props.authToken
    )
      .then((res) => {
        console.log(res);
        this.props.history.push('/claims/feedback', {
          feedback: res.feedbackInfo,
          claimInfo: res.claimInfo,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  removeFile = (ele, idx) => {
    let PresdeleteImage = this.state.prescriptionFileParts.filter(
      (el, fileindex) => {
        return fileindex !== idx;
      }
    );
    this.setState({
      prescriptionFileParts: PresdeleteImage,
    });

    let invoiceDeleteImage = this.state.invoiceFileParts.filter(
      (elm, fileindx) => {
        return fileindx !== idx;
      }
    );
    this.setState({
      invoiceFileParts: invoiceDeleteImage,
    });
  };

  render() {
    const {
      invoiceFile,
      prescriptionFile,
      status,
      claimInfo,
      documentsDeficientDetails,
      documentsDeficient,
      requestStatus,
      labReport,
      borderColor,
      showModal,
      showUploadOption,
      invoiceFileParts,
      prescriptionFileParts,
      labsReportFileParts,
      paymentDetails,
      merFile,
    } = this.state;
    console.log({ state: this.state }, 'renderUploadedFile');
    const renderUploadedFile = (ele, idx) => {
      const { fileUrl, data, type } = ele;
      console.log({ fileUrl, data, type }, 'renderUploadFile');
      if (fileUrl && fileUrl.substr(fileUrl.length - 3) === 'pdf') {
        return (
          <AttachFileContainer
            key={fileUrl || window.URL.createObjectURL(data)}
            fileUrl={fileUrl || window.URL.createObjectURL(data)}
            onTap={() => {
              document.querySelector(`#image-anchor-${type}-${idx}`).click();
            }}
          >
            <a
              href={fileUrl || window.URL.createObjectURL(data)}
              target="_blank"
              rel="noopener noreferrer"
              id={`image-anchor-${type}-${idx}`}
              style={{ display: 'none' }}
            />
          </AttachFileContainer>
        );
      }
      return (
        <AttachImageContainer
          key={fileUrl || window.URL.createObjectURL(data)}
          fileUrl={fileUrl || window.URL.createObjectURL(data)}
          onTap={() => {
            document.querySelector(`#image-anchor-${type}-${idx}`).click();
          }}
        >
          {fileUrl ? (
            <a
              href={fileUrl || window.URL.createObjectURL(data)}
              target="_blank"
              rel="noopener noreferrer"
              id={`image-anchor-${type}-${idx}`}
              style={{ display: 'none' }}
            />
          ) : (
            <CloseContainer onTap={() => this.removeFile(ele.name, idx)}>
              <CloseIcon>
                <a
                  href={fileUrl || window.URL.createObjectURL(data)}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={`image-anchor-${type}-${idx}`}
                  style={{ display: 'none' }}
                />
              </CloseIcon>
            </CloseContainer>
          )}
        </AttachImageContainer>
      );
    };

    const stepsRender = (ele, idx) => {
      console.log(ele);
      return (
        <div
          className={`relative flex ${
            idx + 1 < status.length ? 'border-l-2' : ''
          }`}
          style={
            ele.active === false || ele.showDetails
              ? { borderColor: '#E3E3E3' }
              : { borderColor: '#e07a5f' }
          }
        >
          {(ele.type === 'documents-incomplete' && ele.showDetails === true) ||
          (ele.type === 'upload-document' &&
            this.state.documentUploadExpired === true) ? (
            <StatusIncomDoc
              src={AlertIcon}
              alt="alert"
              height={'24px'}
              width={'24px'}
              style={{
                height: '24px',
                width: '24px',
              }}
            />
          ) : !ele.active ? (
            <StatusIcon completed={false} active={false} />
          ) : idx > 0 && ele.active && !status[idx + 1].active ? (
            <StatusIcon completed={ele.active} active={ele.active} />
          ) : (
            <StatusIconImg src={tickIcon} alt="tick" />
          )}
          {ele.showDetails === false ? (
            <div
              className={`flex items-center w-100 ${
                ele.dateTime ? 'pb-32px' : 'pb-32px'
              }`}
            >
              <StepTextContainer completed={true} active={true}>
                <div
                  className={`text-body-s font-medium ${
                    ele.active ? 'text-txtlight font-semibold' : 'text-sub'
                  } `}
                >
                  {ele.label}
                </div>
                <div className="txt-black-500-11">{ele.description}</div>
              </StepTextContainer>
              <DateText>{ele.dateTime}</DateText>
            </div>
          ) : (
            <div style={{ width: '100%' }}>
              <div
                className={`flex items-center ${
                  ele.dateTime ? 'pb-32px' : 'pb-32px'
                }`}
              >
                <StepstextContainer className="mb-12px">
                  {ele.type === 'reimbursement-request' ||
                  ele.type === 'claim-raised' ? (
                    <div className="rounded-12 border border-custom1eb865">
                      <div className="p-12px ">
                        <div className="text-body-s font-semibold text-txtlight border-b border-txtsecondary-shades7 border-solid flex flex-row ">
                          <img
                            src={CheckTick}
                            alt="tick"
                            height={'15px'}
                            width={'15px'}
                            style={{
                              height: '15px',
                              width: '15px',
                            }}
                            className="mr-3"
                          />
                          {ele.label}
                        </div>
                        <div className="p-10px">
                          <div
                            className="p-10px rounded-12 border border-txtsecondary-shades4"
                            style={{
                              background: '#F0F3F6',
                              left: '10px',
                            }}
                          >
                            <div className="txt-black-500-11">
                              {ele.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {ele.type === 'upload-document' ? (
                        this.state.documentUploadExpired ? (
                          <div className="rounded-12 border border-accent2-shades5">
                            <div className="p-12px text-body-s font-semibold text-txtlight">
                              Request Expired
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="rounded-12 border border-primary">
                              <div className="p-12px text-body-s font-semibold text-txtlight">
                                {ele.label}
                                <div className="flex flex-row items-center">
                                  <img
                                    height={'10px'}
                                    width={'10px'}
                                    style={{
                                      height: '10px',
                                      width: '10px',
                                    }}
                                    className="mr-1 "
                                    src={NotificationIcon}
                                    alt="notificationicon"
                                  />
                                  <p
                                    className="text-title-sm mr-5 p-64px text-sub"
                                    style={{ fontSize: '11px' }}
                                  >
                                    The window is only active till
                                  </p>
                                  <p>{`${ele.activeDate}`}</p>
                                </div>
                                <div>
                                  {invoiceFileParts.length > 0 && (
                                    <div>
                                      <p className="txt-dgrey-600-12 mt-20 mb-5">
                                        Invoice files
                                      </p>

                                      <AttachedFilesContainer>
                                        {invoiceFileParts.map(
                                          renderUploadedFile
                                        )}
                                        {invoiceFileParts.length > 0 &&
                                          invoiceFileParts.length < 5 && (
                                            <div
                                              class="dashed-add"
                                              style={{
                                                marginTop: '0.25rem',
                                                marginBottom: '0.5rem',
                                                width: 45,
                                              }}
                                              onClick={() => {
                                                this.setState({
                                                  showModal: true,
                                                  showUploadOption: true,
                                                  imageType: 'invoice',
                                                });
                                              }}
                                            >
                                              +
                                            </div>
                                          )}
                                      </AttachedFilesContainer>
                                    </div>
                                  )}
                                  {prescriptionFileParts.length > 0 && (
                                    <div>
                                      <p className="txt-dgrey-600-12 mt-20 mb-5">
                                        Prescription files
                                      </p>

                                      <AttachedFilesContainer>
                                        {prescriptionFileParts.map(
                                          renderUploadedFile
                                        )}
                                        {prescriptionFileParts.length > 0 &&
                                          prescriptionFileParts.length < 5 && (
                                            <div
                                              class="dashed-add"
                                              style={{
                                                marginTop: '0.25rem',
                                                marginBottom: '0.5rem',
                                                width: 45,
                                              }}
                                              onClick={() => {
                                                this.setState({
                                                  showModal: true,
                                                  showUploadOption: true,
                                                  imageType: 'prescription',
                                                });
                                              }}
                                            >
                                              +
                                            </div>
                                          )}
                                      </AttachedFilesContainer>
                                    </div>
                                  )}
                                  {invoiceFileParts.length === 0 && (
                                    <p
                                      className="txt-black-600-14 lab-home-btn-blue-dashed pt-10 pb-10 hover mt-20"
                                      onClick={() => {
                                        this.setState({
                                          showModal: true,
                                          showUploadOption: true,
                                          imageType: 'invoice',
                                        });
                                      }}
                                    >
                                      Upload Invoice
                                    </p>
                                  )}
                                  {prescriptionFileParts.length === 0 && (
                                    <p
                                      className="txt-black-600-14 lab-home-btn-blue-dashed pt-10 pb-10 hover mt-20"
                                      onClick={() => {
                                        this.setState({
                                          showModal: true,
                                          showUploadOption: true,
                                          imageType: 'prescription',
                                        });
                                      }}
                                    >
                                      Upload Prescription
                                    </p>
                                  )}
                                  {(invoiceFileParts.length > 0 ||
                                    prescriptionFileParts.length > 0) && (
                                    <StatusDetail>
                                      <p
                                        className="txt-white-600-14 lab-home-btn-blue pt-10 pb-10 hover mt-20"
                                        style={{
                                          background: '#e07a5f',
                                        }}
                                        onClick={() => this.uploadDocs()}
                                      >
                                        Submit
                                      </p>
                                    </StatusDetail>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      ) : ele.type === 'documents-incomplete' ? (
                        <div>
                          <StatusContainer2 borderColor={borderColor}>
                            <StatusDetail bottomBorder>
                              <Text3 active>
                                {documentsDeficientDetails.label}
                              </Text3>
                              <StatusSubLableText>
                                {documentsDeficientDetails.description}
                              </StatusSubLableText>
                            </StatusDetail>

                            {documentsDeficientDetails.type ===
                              'upload-document' ||
                            documentsDeficientDetails.type ===
                              'documents-incomplete' ? (
                              <div>
                                {invoiceFileParts.length > 0 &&
                                  documentsDeficient.includes('invoice') && (
                                    <div>
                                      <p className="txt-dgrey-600-12 mt-20 mb-5">
                                        Invoice files
                                      </p>

                                      <AttachedFilesContainer>
                                        {invoiceFileParts.map(
                                          renderUploadedFile
                                        )}
                                        {invoiceFileParts.length > 0 &&
                                          invoiceFileParts.length < 5 && (
                                            <div
                                              class="dashed-add"
                                              style={{
                                                marginTop: '0.25rem',
                                                marginBottom: '0.5rem',
                                                width: 45,
                                              }}
                                              onClick={() => {
                                                this.setState({
                                                  showModal: true,
                                                  showUploadOption: true,
                                                  imageType: 'invoice',
                                                });
                                              }}
                                            >
                                              +
                                            </div>
                                          )}
                                      </AttachedFilesContainer>
                                    </div>
                                  )}
                                {prescriptionFileParts.length > 0 &&
                                  documentsDeficient.includes(
                                    'prescription'
                                  ) && (
                                    <div>
                                      <p className="txt-dgrey-600-12 mt-20 mb-5">
                                        Prescription files
                                      </p>

                                      <AttachedFilesContainer>
                                        {prescriptionFileParts.map(
                                          renderUploadedFile
                                        )}
                                        {prescriptionFileParts.length > 0 &&
                                          prescriptionFileParts.length < 5 && (
                                            <div
                                              class="dashed-add"
                                              style={{
                                                marginTop: '0.25rem',
                                                marginBottom: '0.5rem',
                                                width: 45,
                                              }}
                                              onClick={() => {
                                                this.setState({
                                                  showModal: true,
                                                  showUploadOption: true,
                                                  imageType:
                                                    'prescriptionFiles',
                                                });
                                              }}
                                            >
                                              +
                                            </div>
                                          )}
                                      </AttachedFilesContainer>
                                    </div>
                                  )}
                                {invoiceFileParts.length === 0 &&
                                documentsDeficient.includes('invoice') ? (
                                  <p
                                    className="txt-black-600-14 lab-home-btn-blue-dashed pt-10 pb-10 hover mt-20"
                                    onClick={() => {
                                      this.setState({
                                        showModal: true,
                                        showUploadOption: true,
                                        imageType: 'invoice',
                                      });
                                    }}
                                  >
                                    Upload Invoice
                                  </p>
                                ) : (
                                  ''
                                )}
                                {prescriptionFileParts.length === 0 &&
                                documentsDeficient.includes('prescription') ? (
                                  <p
                                    className="txt-black-600-14 lab-home-btn-blue-dashed pt-10 pb-10 hover mt-20"
                                    onClick={() => {
                                      this.setState({
                                        showModal: true,
                                        showUploadOption: true,
                                        imageType: 'prescription',
                                      });
                                    }}
                                  >
                                    Upload Prescription
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            ) : null}
                            {documentsDeficientDetails.reason ? (
                              <StatusUploadSubContainer>
                                <StatusSubText>
                                  {documentsDeficientDetails.reason}
                                </StatusSubText>
                              </StatusUploadSubContainer>
                            ) : null}
                            {(invoiceFileParts.length > 0 ||
                              prescriptionFileParts.length > 0 ||
                              labsReportFileParts.length > 0) &&
                            !(
                              documentsDeficientDetails.state ===
                              'documents-uploaded'
                            ) ? (
                              <StatusDetail>
                                <p
                                  className="txt-white-600-14 lab-home-btn-blue pt-10 pb-10 hover mt-20"
                                  style={{
                                    background: '#e07a5f',
                                  }}
                                  onClick={() =>
                                    this.updateReimbursement(
                                      documentsDeficientDetails
                                    )
                                  }
                                >
                                  Submit
                                </p>
                              </StatusDetail>
                            ) : null}
                          </StatusContainer2>
                          <div>{ele.activeDate}</div>
                        </div>
                      ) : ele.type === 'documents-verified' ? (
                        <div className="rounded-12 border border-custom1eb865">
                          {/* <div className=" "> */}
                          <div className="p-12px text-body-s font-semibold text-txtlight border-b border-txtsecondary-shades7 border-solid flex flex-row items-center">
                            <img
                              src={CheckTick}
                              alt="tick"
                              height={'24px'}
                              width={'24px'}
                              style={{
                                height: '24px',
                                width: '24px',
                              }}
                              className="mr-4px"
                            />
                            {ele.label}
                          </div>
                          <div className="px-16px pt-12px">
                            <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 py-8px">
                              <span className="text-body-s font-regular text-txtlight">
                                Requested Amount
                              </span>
                              <span className="text-body-s font-semibold text-txtlight">
                                {`₹ ${ele.requestedAmount}`}
                              </span>
                            </div>
                            <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 py-8px">
                              <span className="text-body-s font-regular text-txtlight">
                                Approved Amount
                              </span>
                              <span className="text-body-s font-semibold text-txtlight">
                                {`₹ ${ele.approvedAmount}`}
                              </span>
                            </div>
                          </div>
                          <div
                            className="txt-black-600-14 w-full hover py-16px text-center"
                            onClick={() => {
                              this.onProceed();
                            }}
                          >
                            <p style={{ color: '#e07a5f' }}>Know More</p>
                          </div>
                        </div>
                      ) : ele.active ? (
                        <div className="bg-textbox border border-primary rounded-12">
                          <div className="text-body-s font-semibold text-txtlight px-16px py-12px border-b border-txtsecondary-shades4">
                            {ele.label}
                          </div>
                          <div className="p-16px pt-12px">
                            <p className="font-regular text-txtlight text-body-s p-12px bg-txtsecondary-shades4 bg-opacity-60 rounded-12">
                              {ele.description}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="rounded-12 border border-custom1eb865">
                          <div className="text-body-s font-semibold text-txtlight px-16px py-12px">
                            {ele.label}
                          </div>
                          <div className="txt-black-500-11">
                            {ele.description}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </StepstextContainer>
                <DateText>{ele.dateTime}</DateText>
              </div>
            </div>
          )}
        </div>
      );
    };
    return (
      <OuterContainer>
        <Header
          title="Summary"
          onTap={() => {
            if (this.state.goBack) {
              this.props.history.goBack();
            } else {
              this.props.history.replace('/home/consult');
            }
          }}
        />
        {this.state.loading ? (
          <LoadingComponent />
        ) : (
          <BodyContainer>
            <div>
              <div>
                {this.state.documentUploadExpired === true ? (
                  <div
                    className="rounded-12 w-full p-16px h-164px mb-20"
                    style={{ background: 'rgba(242, 85, 64, 0.05)' }}
                  >
                    <div className="flex flex-row items-center">
                      <img src={DeniedPermisionIcon} alt="deniend" />
                      <span className="text-title-m ml-10px font-semibold p-64px text-txtlight">
                        Upload Document Request Expired
                      </span>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                {this.state.claimInfoV2 &&
                this.state.claimInfoV2.claimStatus === 'rejected' ? (
                  <div className="mb-20">
                    <div
                      className="rounded-12 w-full p-16px h-164px "
                      style={{ background: 'rgba(242, 85, 64, 0.05)' }}
                    >
                      <div className="flex flex-row items-center">
                        <img src={DeniedPermisionIcon} alt="deniend" />
                        <span className="text-title-m ml-10px font-semibold p-64px text-txtlight">
                          Claim Rejected
                        </span>
                      </div>
                      {this.state.claimStatusDetails &&
                      this.state.claimStatusDetails.reason ? (
                        <div className="ml-32px">
                          <p>{this.state.claimStatusDetails.reason}</p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div>
                {this.state.claimInfoV2 &&
                (this.state.claimInfoV2.claimStatus === 'approved' ||
                  this.state.claimInfoV2.claimStatus === 'settled') ? (
                  <div className="mb-24px">
                    <div className="mb-20 bg-custom1eb865 bg-opacity-10 rounded-12 p-16px text-body-l text-txtlight font-regular">
                      <div className="flex flex-row items-center">
                        <img src={CircleCheckIcon} alt="checkicon" />
                        <span className="text-body-l ml-10px font-semibold text-txtlight">
                          {paymentDetails &&
                          paymentDetails.settledAmount &&
                          this.state.claimInfoV2 &&
                          this.state.claimInfoV2.claimAmount &&
                          Number(paymentDetails.settledAmount) <
                            Number(this.state.claimInfoV2.claimAmount)
                            ? 'Claim Partially Approved'
                            : 'Claim Approved'}
                        </span>
                      </div>
                      <div
                        className="w-full hover ml-32px pt-4px"
                        onClick={() => {
                          this.onProceed();
                        }}
                      >
                        <p
                          className="text-body-s font-medium"
                          style={{ color: '#3F8CFF' }}
                        >
                          Know More
                        </p>
                      </div>
                    </div>
                    {/* {this.state.claimStatusDetails.submittedFeedback ===
                    false ? (
                      <div
                        className="txt-black-600-14 w-full hover pb-5 text-center"
                        onClick={() => {
                          this.toFeedbackPage(
                            this.state.claimInfoV2.reimbursementId
                          );
                        }}
                      >
                        <span style={{ color: '#e07a5f' }}>
                          Rate your Experience
                        </span>
                      </div>
                    ) : (
                      ''
                    )} */}
                    <div className="flex flex-row items-center">
                      <img src={RuppesIcon} alt="rupees" />
                      <p className="text-title-m ml-10px font-semibold p-64px text-txtlight">
                        Payment Details
                      </p>
                    </div>
                    <div className="mt-16px">
                      <div className="rounded-12 border border-custom1eb865 px-16px pt-4px">
                        <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 py-12px">
                          <span className="text-body-s font-medium text-txtsecondary">
                            Settled Amount
                          </span>
                          <span className="text-body-s font-semibold text-txtlight">
                            {`₹ ${this.state.paymentDetails.settledAmount}`}
                          </span>
                        </div>

                        <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 py-12px">
                          <span className="text-body-s font-medium text-txtsecondary">
                            UTR Number
                          </span>
                          <span className="text-body-s font-semibold text-txtlight">
                            {this.state.paymentDetails.utrNumber}
                          </span>
                        </div>

                        <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 py-12px">
                          <span className="text-body-s font-medium text-txtsecondary">
                            Time of transaction
                          </span>
                          <span className="text-body-s font-semibold text-txtlight">
                            {this.state.paymentDetails.timeOfTransaction}
                          </span>
                        </div>

                        <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 py-12px">
                          <span className="text-body-s font-medium text-txtsecondary">
                            Account Number
                          </span>
                          <span className="text-body-s font-semibold text-txtlight">
                            {this.state.paymentDetails.AccountNumber}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="flex flex-row items-center">
                <img src={DetailsIcon} alt="detail" />
                <p className="text-title-m ml-10px font-semibold p-64px text-txtlight">
                  Details
                </p>
              </div>
              <div className="mt-14px mb-24px">
                <div className=" rounded-12 border border-txtsecondary-shades7  opacity-20px">
                  {/* <div className="order-status"> */}
                  <div className="order-status-details">
                    <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 pb-12px pt-12px">
                      <span className="text-body-s font-medium text-txtsecondary">
                        Claim Id
                      </span>
                      <span className="text-body-s font-semibold text-txtlight">
                        {this.state.claimInfoV2.reimbursementId}
                      </span>
                    </div>
                    <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 pb-12px pt-12px">
                      <span className="text-body-s font-medium text-txtsecondary">
                        Patient Name
                      </span>
                      <span className="text-body-s font-semibold text-txtlight">
                        {this.state.claimInfoV2.patientInfo.name}
                      </span>
                    </div>
                    <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 pb-12px pt-12px">
                      <span className="text-body-s font-medium text-txtsecondary">
                        Claim Category
                      </span>
                      <span className="text-body-s font-semibold text-txtlight">
                        {this.state.claimInfoV2.procedureName}
                      </span>
                    </div>
                    <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 pb-12px pt-12px">
                      <span className="text-body-s font-medium text-txtsecondary">
                        Procedure Date
                      </span>
                      <span className="text-body-s font-semibold text-txtlight">
                        {this.state.claimInfoV2.admissionDate}
                      </span>
                    </div>
                    <div className="flex-start-end align-items-center border-b border-txtsecondary-shades4 pb-12px pt-12px">
                      <span className="text-body-s font-medium text-txtsecondary">
                        Tentative Amount
                      </span>
                      <span className="text-body-s font-semibold text-txtlight">
                        {`₹ ${this.state.claimInfoV2.claimAmount}`}
                      </span>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
            {(this.state.claimInfoV2 &&
              this.state.claimInfoV2.claimStatus === 'approved') ||
            this.state.claimInfoV2.claimStatus === 'rejected' ||
            this.state.claimInfoV2.claimStatus === 'settled' ? (
              ' '
            ) : (
              <div>
                <div className="flex flex-row items-center">
                  <img src={DetailsIcon} alt="detail" />
                  <p className="text-title-m ml-10px font-semibold p-64px text-txtlight">
                    Claim status
                  </p>
                </div>
                <div className="p-20px pb-0 rounded-12  border-box opacity-20px">
                  <StepsContainer>{status.map(stepsRender)}</StepsContainer>
                </div>
              </div>
            )}
            {/* {requestStatus === 'documents-deficient' &&
            documentsDeficientDetails &&
            documentsDeficient &&
            documentsDeficient.length > 0 ? (
              <div>
                <StatusContainer2 borderColor={borderColor}>
                  <StatusDetail bottomBorder>
                    <Text3 active>{documentsDeficientDetails.label}</Text3>
                    <StatusSubLableText>
                      {documentsDeficientDetails.description}
                    </StatusSubLableText>
                  </StatusDetail>

                  {documentsDeficientDetails.type === 'upload-document' ||
                  documentsDeficientDetails.type === 'documents-incomplete' ? (
                    <div>
                      {invoiceFileParts.length > 0 &&
                        documentsDeficient.includes('invoice') && (
                          <div>
                            <p className="txt-dgrey-600-12 mt-20 mb-5">
                              Invoice files
                            </p>

                            <AttachedFilesContainer>
                              {invoiceFileParts.map(renderUploadedFile)}
                              {invoiceFileParts.length > 0 &&
                                invoiceFileParts.length < 5 && (
                                  <div
                                    class="dashed-add"
                                    style={{
                                      marginTop: '0.25rem',
                                      marginBottom: '0.5rem',
                                      width: 45,
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        showModal: true,
                                        showUploadOption: true,
                                        imageType: 'invoice',
                                      });
                                    }}
                                  >
                                    +
                                  </div>
                                )}
                            </AttachedFilesContainer>
                          </div>
                        )}
                      {prescriptionFileParts.length > 0 &&
                        documentsDeficient.includes('prescription') && (
                          <div>
                            <p className="txt-dgrey-600-12 mt-20 mb-5">
                              Prescription files
                            </p>

                            <AttachedFilesContainer>
                              {prescriptionFileParts.map(renderUploadedFile)}
                              {prescriptionFileParts.length > 0 &&
                                prescriptionFileParts.length < 5 && (
                                  <div
                                    class="dashed-add"
                                    style={{
                                      marginTop: '0.25rem',
                                      marginBottom: '0.5rem',
                                      width: 45,
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        showModal: true,
                                        showUploadOption: true,
                                        imageType: 'prescriptionFiles',
                                      });
                                    }}
                                  >
                                    +
                                  </div>
                                )}
                            </AttachedFilesContainer>
                          </div>
                        )}
                      {labsReportFileParts.length > 0 &&
                        documentsDeficient.includes('lab-report') && (
                          <div>
                            <p className="txt-dgrey-600-12 mt-20 mb-5">
                              Lab Report
                            </p>

                            <AttachedFilesContainer>
                              {labsReportFileParts.map(renderUploadedFile)}
                              {labsReportFileParts.length > 0 &&
                                labsReportFileParts.length < 5 && (
                                  <div
                                    class="dashed-add"
                                    style={{
                                      marginTop: '0.25rem',
                                      marginBottom: '0.5rem',
                                      width: 45,
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        showModal: true,
                                        showUploadOption: true,
                                        imageType: 'lab-report',
                                      });
                                    }}
                                  >
                                    +
                                  </div>
                                )}
                            </AttachedFilesContainer>
                          </div>
                        )}
                      {invoiceFileParts.length === 0 &&
                      documentsDeficient.includes('invoice') ? (
                        <p
                          className="txt-black-600-14 lab-home-btn-blue-dashed pt-10 pb-10 hover mt-20"
                          onClick={() => {
                            this.setState({
                              showModal: true,
                              showUploadOption: true,
                              imageType: 'invoice',
                            });
                          }}
                        >
                          Upload Invoice
                        </p>
                      ) : (
                        ''
                      )}
                      {prescriptionFileParts.length === 0 &&
                      documentsDeficient.includes('prescription') ? (
                        <p
                          className="txt-black-600-14 lab-home-btn-blue-dashed pt-10 pb-10 hover mt-20"
                          onClick={() => {
                            this.setState({
                              showModal: true,
                              showUploadOption: true,
                              imageType: 'prescription',
                            });
                          }}
                        >
                          Upload Prescription
                        </p>
                      ) : (
                        ''
                      )}
                      {labsReportFileParts.length === 0 &&
                      documentsDeficient.includes('lab-report') ? (
                        <p
                          className="txt-black-600-14 lab-home-btn-blue-dashed pt-10 pb-10 hover mt-20"
                          onClick={() => {
                            this.setState({
                              showModal: true,
                              showUploadOption: true,
                              imageType: 'lab-report',
                            });
                          }}
                        >
                          Upload Lab Report
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}
                  {documentsDeficientDetails.reason ? (
                    <StatusUploadSubContainer>
                      <StatusSubText>
                        {documentsDeficientDetails.reason}
                      </StatusSubText>
                    </StatusUploadSubContainer>
                  ) : null}
                  {(invoiceFileParts.length > 0 ||
                    prescriptionFileParts.length > 0 ||
                    labsReportFileParts.length > 0) &&
                  !(
                    documentsDeficientDetails.state === 'documents-uploaded'
                  ) ? (
                    <StatusDetail>
                      <p
                        className="txt-white-600-14 lab-home-btn-blue pt-10 pb-10 hover mt-20"
                        style={{
                          background: '#e07a5f',
                        }}
                        onClick={() =>
                          this.updateReimbursement(documentsDeficientDetails)
                        }
                      >
                        Submit
                      </p>
                    </StatusDetail>
                  ) : null}
                </StatusContainer2>
                <DateText>
                  {documentsDeficientDetails.dateTime
                    ? documentsDeficientDetails.dateTime
                    : null}
                </DateText>
              </div>
            ) : null} */}
            <div>
              <div className="flex flex-row items-center">
                <img src={DocumentuploadIcon} alt="detail" />
                <p className="text-title-m ml-10px font-semibold p-64px text-txtlight">
                  Documents
                </p>
              </div>
            </div>
            {prescriptionFile.length > 0 && (
              <div className="order-status">
                <div className="order-status-details">
                  <div className="text-body-s font-semibold text-txtlight border-bottom">
                    Prescription
                  </div>
                  <AttachedFilesContainer>
                    {prescriptionFile.map(renderUploadedFile)}
                  </AttachedFilesContainer>
                </div>
              </div>
            )}
            {invoiceFile.length > 0 && (
              <div className="order-status">
                <div className="order-status-details">
                  <div className="text-body-s font-semibold text-txtlight border-bottom">
                    Invoices
                  </div>
                  <AttachedFilesContainer>
                    {invoiceFile.map(renderUploadedFile)}
                  </AttachedFilesContainer>
                </div>
              </div>
            )}
            {merFile.length > 0 && (
              <div className="order-status">
                <div className="order-status-details">
                  <div className="text-body-s font-semibold text-txtlight border-bottom">
                    Mer
                  </div>
                  <AttachedFilesContainer>
                    {merFile.map(renderUploadedFile)}
                  </AttachedFilesContainer>
                </div>
              </div>
            )}
            {/* {labReport.length > 0 && (
              <div className="order-status">
                <div className="order-status-details">
                  <div className="text-body-s font-semibold text-txtlight border-bottom">
                    Lab Reports
                  </div>
                  <AttachedFilesContainer>
                    {labReport.map(renderUploadedFile)}
                  </AttachedFilesContainer>
                </div>
              </div>
            )} */}
          </BodyContainer>
        )}
        <AttachmentInput
          type="file"
          id="photoAttachment"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={this.onImageDataReceive}
        />
        <AttachmentInput
          type="file"
          id="fileAttachment"
          accept=".pdf"
          style={{ display: 'none' }}
          onChange={this.onPDFDataReceive}
        />
        {showModal && (
          <Modal>
            <ModalContainer onClick={this.handleOutsideClick}>
              <FlyInTransition
                in={showUploadOption}
                mountOnEnter
                unmountOnExit
                onExited={this.hideModal}
                appear
              >
                <ModalAttachContainer ref={(node) => (this.node = node)}>
                  <AttachOptionsContainer>
                    <AttachOption onTap={() => this.onAttachPhoto()}>
                      <AttachOptionInnerContainer>
                        <AttachOptionIcon src={photoIcon} />
                        <AttachOptionText>Gallery</AttachOptionText>
                      </AttachOptionInnerContainer>
                    </AttachOption>
                    <AttachOption onTap={() => this.onAttachFile()}>
                      <AttachOptionInnerContainer>
                        <AttachOptionIcon src={fileIcon} />
                        <AttachOptionText>pdf</AttachOptionText>
                      </AttachOptionInnerContainer>
                    </AttachOption>
                  </AttachOptionsContainer>
                </ModalAttachContainer>
              </FlyInTransition>
            </ModalContainer>
          </Modal>
        )}
        {this.state.showMessageModal ? (
          <MessageModal
            header={this.state.modalHeader}
            message={this.state.modalMessage}
            hideModal={this.hideModal}
          />
        ) : (
          ''
        )}
        {this.state.showKnowMoreModal && (
          <ClaimsKnowMore
            hideModal={this.hideModal}
            data={this.state.knowMoreDetails}
          />
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(ClaimsSummary);
