import styled from 'styled-components';

const Text = styled.p`
  margin: ${(props) => props.margin || 0};
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: ${(props) => props.color || 'black'};
  font-size: ${(props) => props.fontSize || '1rem'};
  line-height: ${(props) => props.lineHeight || 'unset'};
`;

const BoldText = styled(Text)`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
`;

const SemiBoldText = styled(Text)`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
`;

const BlackText = styled(Text)`
  font-family: 'Inter', sans-serif;
`;

const RegularText = styled(Text)`
  font-family: 'Inter', sans-serif;
  font-weight: normal;
`;

const ButtonText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  padding: 6px;
  color: ${(props) => props.color || '#FFFFFF'};
  text-align: center;
`;

const SubscriptionText = styled(ButtonText)`
  background-image: linear-gradient(to left, #ffa14f, #db4c6d);
  background-clip: text;
  -webkit-background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

const LinkText = styled(BoldText)`
  display: inline-block;
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: #8852cc;
`;

const TextInput = styled.input`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  border: none;
`;

export {
  Text,
  BoldText,
  ButtonText,
  TextInput,
  BlackText,
  LinkText,
  RegularText,
  SubscriptionText,
  SemiBoldText,
};
