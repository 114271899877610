import { Component } from 'react';
import { connect } from 'react-redux';
import supportIcon from '../../images/maternity-care-program/supportIcon.png';
import backArrow from '../../images/maternity-care-program/backArrow.png';
import maternityPlanIcon from '../../images/maternity-care-program/maternity-plan-icon.png';
import blueArrow from '../../images/maternity-care-program/bluearrow.png';
import yellowArrow from '../../images/maternity-care-program/yellowarrow.png';
import redArrow from '../../images/maternity-care-program/redarrow.png';
import purpleArrow from '../../images/maternity-care-program/purplearrow.png';
import benefitCard1 from '../../images/maternity-care-program/benefitCard1.png';
import benefitCard2 from '../../images/maternity-care-program/benefitCard2.png';
import benefitCard3 from '../../images/maternity-care-program/benefitCard3.png';
import benefitCard4 from '../../images/maternity-care-program/benefitCard4.png';
import benefitIcon3 from '../../images/maternity-care-program/benefit3.png';
import benefitIcon6 from '../../images/maternity-care-program/benefit6.png';
import newDemoBenefit from '../../images/maternity-care-program/newDemoBenefit.png';
import initializeFreshDesk from '../../utils/freshchat';
import { getMaternityBenefitDetails } from '../../services/maternity-care-program';

export class MaternityBenefitsPageDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gynecologistSessions: 0,
      psychologistConsult: 0,
      physicianConsult: 0,
      nutritionManagement: 0,
      startDate: '',
      endDate: '',
      benefits: [
        {
          description: 'Curated_Fitness_Content Workout + Yoga',
          icon: benefitIcon3,
        },
        {
          description:
            '2_Expert_Webinars Prenatal & Postpartum Care (group session)',
          icon: benefitIcon6,
        },
      ],
    };
  }

  componentDidMount = () => {
    this.fetchBenefitDetails();
  };

  fetchBenefitDetails = () => {
    getMaternityBenefitDetails(this.props.authToken).then((data) => {
      this.setState({
        gynecologistSessions: data.gynacCount,
        psychologistConsult: data.psychoCount,
        physicianConsult: data.physicianCount,
        nutritionManagement: data.nutriCount,
        startDate: data.startingDate,
        endDate: data.endingDate,
      });
    });
  };

  closeView = () => {
    if (typeof window.Android !== 'undefined') {
      return window.Android.closeView();
    }
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'closeView',
        })
      );
    }
  };

  openSupport = () => {
    initializeFreshDesk(this.props.user);
  };

  openTeleConsultation = (verticalId) => {
    if (typeof window.Android !== 'undefined') {
      return window.Android.openDoctorListScreen(verticalId);
    }
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          method: 'openDoctorListScreen',
          data: verticalId,
        })
      );
    }
  };

  render() {
    const { benefits } = this.state;
    return (
      <div className="w-full overflow-auto">
        <div className="flex flex-row justify-between  pt-7 pb-4  border-b border-txtsecondary-shades4 ">
          <div className="flex flex-row pl-2">
            {/* <img src={backArrow} onClick={this.closeView}></img> */}
            <span className="font-semibold  mt-1 ml-5">Maternity Care</span>
          </div>
        </div>
        <div className="p-4">
          <span className="font-semibold">Active Plans</span>
          <div
            className="border border-solid rounded-2xl p-2"
            style={{ marginTop: '15px', borderColor: '#7CC698' }}
          >
            <div className="flex flex-row p-2 ">
              <img src={maternityPlanIcon} className="mr-10" />
              <span
                style={{ fontWeight: 'bold', fontSize: '14px', padding: '3px' }}
              >
                Maternity Plan
              </span>
            </div>
            <div className="flex flex-row justify-between p-2 pt-0">
              <span style={{ fontSize: '12px', color: '#767787' }}>
                Purchased on:
                <span className="font-semibold text-[#45454F]">
                  {' '}
                  {this.state.startDate}
                </span>
              </span>
              <span style={{ fontSize: '12px', color: '#767787' }}>
                Expiring on:
                <span className="font-semibold text-[#45454F]">
                  {' '}
                  {this.state.endDate}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="p-4">
          <span className="font-semibold">My Benefits</span>
          <div className="grid grid-cols-2 mt-15 gap-3">
            <div
              className="border border-solid rounded-2xl p-2 flex flex-col"
              style={{ borderColor: '#FB9B9B' }}
              onClick={() => this.openTeleConsultation(5)}
            >
              <div className="p-2 flex flex-row justify-between items-center">
                <img src={benefitCard1} />
                <img
                  src={redArrow}
                  style={{
                    width: '19px',
                    height: '19px',
                  }}
                />
              </div>
              <p className="font-semibold p-2">
                Gynaecologist Teleconsultations &amp; Sessions
              </p>
              <div className="p-2 pt-0 flex flex-row justify-between">
                <span
                  style={{
                    color: '#767787',
                    fontSize: '12px',
                    marginTop: '8px',
                  }}
                >
                  Times Booked
                </span>
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  {this.state.gynecologistSessions}
                </span>
              </div>
            </div>
            <div
              className="border border-solid rounded-2xl p-2"
              style={{ borderColor: '#9ACFFF' }}
              onClick={() => this.openTeleConsultation(1)}
            >
              <div className="p-2 flex flex-row justify-between items-center">
                <img src={benefitCard2} />
                <img
                  src={blueArrow}
                  style={{
                    width: '19px',
                    height: '19px',
                  }}
                />
              </div>
              <p className="font-semibold p-2">
                Unlimited Psychologist Teleconsultations
              </p>
              <div className="p-2 pt-0 flex flex-row justify-between">
                <span
                  style={{
                    color: '#767787',
                    fontSize: '12px',
                    marginTop: '8px',
                  }}
                >
                  Times Booked
                </span>
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  {this.state.psychologistConsult}
                </span>
              </div>
            </div>
            <div
              className="border border-solid rounded-2xl p-2 flex flex-col justify-between"
              style={{ borderColor: '#C7B3FF' }}
              onClick={() => this.openTeleConsultation(3)}
            >
              <div className="p-2 flex flex-row justify-between items-center">
                <img src={benefitCard3} />
                <img
                  src={purpleArrow}
                  style={{
                    width: '19px',
                    height: '19px',
                  }}
                />
              </div>
              <p className="font-semibold p-2">
                General Physician Teleconsultations
              </p>
              <div className="p-2 pt-0 flex flex-row justify-between">
                <span
                  style={{
                    color: '#767787',
                    fontSize: '12px',
                    marginTop: '8px',
                  }}
                >
                  Times Booked
                </span>
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  {this.state.physicianConsult}
                </span>
              </div>
            </div>
            <div
              className="border border-solid rounded-2xl p-2"
              style={{ borderColor: '#FFC37D' }}
              onClick={() => this.openTeleConsultation(4)}
            >
              <div className="p-2 flex flex-row justify-between items-center">
                <img src={benefitCard4} />
                <img
                  src={yellowArrow}
                  style={{
                    width: '19px',
                    height: '19px',
                  }}
                />
              </div>
              <p className="font-semibold p-2">
                Personalised Nutrition Management
              </p>
              <div className="p-2 pt-0 flex flex-row justify-between">
                <span
                  style={{
                    color: '#767787',
                    fontSize: '12px',
                    marginTop: '8px',
                  }}
                >
                  Times Booked
                </span>
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  {this.state.nutritionManagement}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="p-4">
          <span className="font-semibold" style={{ fontSize: '16px' }}>
            About the Program
          </span>
          <div className="benefits">
            <p className="mt-12" style={{ fontSize: '14px' }}>
              A pre and post-natal program that is designed to prepare fathers
              and mothers to-be (employee & spouse) through the beautiful
              journey of pregnancy and childbirth.
            </p>
            <p style={{ fontSize: '14px' }}>
              The aim of this program is to reduces stress, and equip women and
              their partners for a new life.
            </p>
            <p className="mt-12" style={{ fontSize: '14px' }}>
              Speak to trusted and experienced doctors about:
            </p>
            <img
              src={newDemoBenefit}
              style={{
                width: '314px',
                height: '364px',
                marginLeft: '21px',
                marginTop: '20px',
              }}
            />
            <p className="mt-40" style={{ fontSize: '14px' }}>
              We also have exclusive health tips, recipes, Expert Webinars and
              much more curated just for you!
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userName: state.user.userName,
  user: state.user,
});

export default connect(mapStateToProps)(MaternityBenefitsPageDemo);
