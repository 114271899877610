import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from '../../components/common/Text';
import { FlyInTransition } from '../../components/transitions/transitions';
import arrowBack from '../../images/arrow_back.svg';
import Modal from '../common/Modal';
import Player from './FitnessPlayer';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  background: white;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 0.5rem;
  z-index: 10;
  padding: 0px 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f3f6;
`;

const HeaderText = styled(Text)`
  margin-left: 0.625rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #464548;
`;

const HeaderNew = styled.button`
  padding: 30px 01px 0px 0px;
  display: flex;
  background: white;
  align-self: stretch;
  align-items: center;
`;

const BodyContainer = styled.div`
  flex: 1;
  flex-shrink: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  margin-top: -9px;
`;

const HoverContent = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  padding: 12px 11px;
  bottom: 0px;
  left: 0px;
  border-radius: 0px 0px 18px 18px;
  width: 180px;
  color: #ffffff;
`;

const AlbumImage = styled.img`
  width: 180px;
  height: 180px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  @media screen and (max-width: 440px) {
    width: 150px;
    height: 150px;
  }
`;

const CardContent = styled.div`
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 10px 0 14px 16px;
  @media screen and (max-width: 440px) {
    width: 150px;
    padding: 10px;
  }
`;

const CardTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0f0b28;
`;

const CardDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #585969;
  margin-top: 2px;
`;

const ModalContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.5);
  overflow: hidden;
`;

const SeeAllFitness = function (props) {
  const { state } = props.location;
  const card = state.card;
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [selectedAlbum, setSelectedAlbum] = useState(null);
  const { authToken } = useSelector((state) => state.user);
  const history = useHistory();

  const playPodcast = (album) => {
    if (album.showAudioPlayer) {
      setShowPlayerModal(true);
      setSelectedAlbum(album);
    } else {
      history.push('/health-content/sub-fitness', {
        albumId: album.albumId,
      });
    }
  };

  const hideModal = () => {
    setShowPlayerModal(false);
  };

  const renderPodcastCards = (card) => {
    const albums = card.albums;
    console.log(albums);
    return (
      <div className="p-24px">
        {albums && albums.length > 0 && (
          <div className="flex flex-row flex-wrap justify-between gap-y-6 gap-x-0">
            {albums.map((album) => {
              return (
                <button
                  onClick={() => playPodcast(album)}
                  className="text-left flex flex-col relative rounded-12"
                >
                  {album.sessionCount && (
                    <p
                      className="absolute px-8px py-2px text-card-body font-semibold text-textbox rounded-12 top-12px left-12px"
                      style={{
                        background: '#0F0B2833',
                      }}
                    >
                      {album.sessionCount} Videos
                    </p>
                  )}
                  <AlbumImage src={album.imageUrl} />
                  <CardContent>
                    <CardTitle>{album.albumTitle}</CardTitle>
                    <CardDescription>By {album.coachName}</CardDescription>
                  </CardContent>
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  };
  return (
    <OuterContainer>
      <HeaderContainer>
        <HeaderNew>
          <img
            src={arrowBack}
            className="cursor-pointer"
            onClick={props.history.goBack}
            style={{ width: '36px' }}
          />
          <HeaderText>{card.title}</HeaderText>
        </HeaderNew>
      </HeaderContainer>
      <BodyContainer>
        <div>{renderPodcastCards(card)}</div>
      </BodyContainer>
      {showPlayerModal && (
        <Modal>
          <ModalContainer onClick={hideModal}>
            <FlyInTransition
              in={showPlayerModal}
              mountOnEnter
              unmountOnExit
              appear
            >
              <Player
                album={selectedAlbum}
                authToken={authToken}
                hideModal={hideModal}
              />
            </FlyInTransition>
          </ModalContainer>
        </Modal>
      )}
    </OuterContainer>
  );
};

export default SeeAllFitness;
